import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
} from 'react-router-dom';

import './App.css';
import { LandingPage } from './components/LandingPage';
import { ConfigurePage } from './pages/ConfigurePage';
import { LoginPage } from './pages/LoginPage';
import {
  Button,
  AppBar,
  ThemeProvider,
  createMuiTheme,
  Typography,
} from '@material-ui/core';
import css from '@emotion/css';
import { hasValidToken } from './services/auth-service';
import { pathOr } from 'ramda';
import tw from 'tailwind.macro';
import { LoadingPage } from './pages';

enum AuthenticationStatus {
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
  NotAllowed = 'not-allowed',
}

const hostname = process.env.REACT_APP_HOST || 'http://leaptide:3000';

const ProtectedRoute: React.FC = ({ children }) => {
  //@todo cookie
  // @todo validate the web token here and async redirect people who get logged out
  // @todo renew JWT before the timeout
  const [authenticationStatus, setAuthenticationStatus] = React.useState(
    AuthenticationStatus.Pending,
  );
  const { id: idParam = 'username' } = useParams(); // @todo weird default value here, shouldnt need to declare this
  const [id, setId] = React.useState(idParam);

  React.useEffect(() => {
    async function fetchMyAPI() {
      const response = await hasValidToken(id);
      const { tokenIsValid } = response;
      const responseId = pathOr('username', ['id'], response);
      if (tokenIsValid) {
        if (responseId !== 'username' && id !== responseId) {
          setId(responseId);
          return setAuthenticationStatus(AuthenticationStatus.NotAllowed);
        }
        return setAuthenticationStatus(AuthenticationStatus.Success);
      }
      return setAuthenticationStatus(AuthenticationStatus.Error);
    }

    fetchMyAPI();
  }, [id]);

  if (authenticationStatus === AuthenticationStatus.NotAllowed) {
    return <Redirect to={`/configure/${id}`} />;
  }

  if (authenticationStatus === AuthenticationStatus.Pending) {
    return <LoadingPage />;
  }

  return <>{children}</>;
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#71c2dc',
      contrastText: '#ffffff',
    },
  },
});

const Header: React.FC = () => (
  <AppBar
    css={css`
      padding: 0.25rem 1rem;
      height: 50px;
      display: flex;
      justify-content: center;
    `}
    position="static"
  >
    <Link
      css={css`
        text-decoration: none;
      `}
      to="/"
    >
      <div
        css={css`
          color: white;
          display: flex;
          align-items: center;
        `}
      >
        <span>
          <svg
            height="25"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="presentation"
          >
            <path
              fill="#ffffff"
              d="M17 16.99c-1.35 0-2.2.42-2.95.8-.65.33-1.18.6-2.05.6-.9 0-1.4-.25-2.05-.6-.75-.38-1.57-.8-2.95-.8s-2.2.42-2.95.8c-.65.33-1.17.6-2.05.6v1.95c1.35 0 2.2-.42 2.95-.8.65-.33 1.17-.6 2.05-.6s1.4.25 2.05.6c.75.38 1.57.8 2.95.8s2.2-.42 2.95-.8c.65-.33 1.18-.6 2.05-.6.9 0 1.4.25 2.05.6.75.38 1.58.8 2.95.8v-1.95c-.9 0-1.4-.25-2.05-.6-.75-.38-1.6-.8-2.95-.8zm0-4.45c-1.35 0-2.2.43-2.95.8-.65.32-1.18.6-2.05.6-.9 0-1.4-.25-2.05-.6-.75-.38-1.57-.8-2.95-.8s-2.2.43-2.95.8c-.65.32-1.17.6-2.05.6v1.95c1.35 0 2.2-.43 2.95-.8.65-.35 1.15-.6 2.05-.6s1.4.25 2.05.6c.75.38 1.57.8 2.95.8s2.2-.43 2.95-.8c.65-.35 1.15-.6 2.05-.6s1.4.25 2.05.6c.75.38 1.58.8 2.95.8v-1.95c-.9 0-1.4-.25-2.05-.6-.75-.38-1.6-.8-2.95-.8zm2.95-8.08c-.75-.38-1.58-.8-2.95-.8s-2.2.42-2.95.8c-.65.32-1.18.6-2.05.6-.9 0-1.4-.25-2.05-.6-.75-.37-1.57-.8-2.95-.8s-2.2.42-2.95.8c-.65.33-1.17.6-2.05.6v1.93c1.35 0 2.2-.43 2.95-.8.65-.33 1.17-.6 2.05-.6s1.4.25 2.05.6c.75.38 1.57.8 2.95.8s2.2-.43 2.95-.8c.65-.32 1.18-.6 2.05-.6.9 0 1.4.25 2.05.6.75.38 1.58.8 2.95.8V5.04c-.9 0-1.4-.25-2.05-.58zM17 8.09c-1.35 0-2.2.43-2.95.8-.65.35-1.15.6-2.05.6s-1.4-.25-2.05-.6c-.75-.38-1.57-.8-2.95-.8s-2.2.43-2.95.8c-.65.35-1.15.6-2.05.6v1.95c1.35 0 2.2-.43 2.95-.8.65-.32 1.18-.6 2.05-.6s1.4.25 2.05.6c.75.38 1.57.8 2.95.8s2.2-.43 2.95-.8c.65-.32 1.18-.6 2.05-.6.9 0 1.4.25 2.05.6.75.38 1.58.8 2.95.8V9.49c-.9 0-1.4-.25-2.05-.6-.75-.38-1.6-.8-2.95-.8z"
            ></path>
          </svg>
        </span>
        <Typography
          css={css`
            && {
              margin-left: 0.5rem;
            }
          `}
        >
          LEAPTIDE
        </Typography>
      </div>
    </Link>
  </AppBar>
);

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route
            path="/"
            exact
            children={
              <div
                css={css`
                  ${tw`mt-4 flex flex-col justify-center`};
                `}
              >
                <>
                  <Header></Header>
                  <Link
                    css={css`
                      color: white;
                      text-decoration: none;
                      margin: 0.5rem auto;
                    `}
                    to="/login"
                  >
                    <Button variant="contained">Login</Button>
                  </Link>
                  <Link
                    css={css`
                      color: white;
                      text-decoration: none;
                      margin: 0.5rem auto;
                    `}
                    to="/signup"
                  >
                    <Button color="primary" variant="contained">
                      Sign Up
                    </Button>
                  </Link>
                </>
              </div>
            }
          ></Route>
          <Route
            exact
            path="/login"
            children={
              <>
                <Header></Header>
                <LoginPage />
              </>
            }
          ></Route>
          <Route
            exact
            path="/signup"
            children={
              <>
                <Header></Header>
                <LoginPage newUser={true} />
              </>
            }
          ></Route>
          <Route
            path="/configure/:id"
            children={
              <ProtectedRoute>
                <Header></Header>
                <ConfigurePage hostname={hostname} />
              </ProtectedRoute>
            }
          ></Route>
          <Route path="/:id" children={<LandingPage />} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};
