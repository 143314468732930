import React from 'react';
import css from '@emotion/css';
import tw from 'tailwind.macro';
import { Theme } from '../structures';

export const Link: React.FC<{ label: string; src: string }> = ({
  label,
  src,
}) => (
  <div
    css={css`
      ${tw`flex justify-center mt-4`};
    `}
  >
    <a
      css={(theme: Theme) => ({
        boxSizing: 'border-box',
        textAlign: 'center',
        color: theme.colors.secondary.contrastColor,
        background:
          theme.colors.secondary.opacity === 0
            ? 'transparent'
            : theme.colors.secondary.color,
        textDecoration: 'none',
        padding: '1rem',
        border: `${theme.borders.size}px solid ${theme.colors.secondary.contrastColor}`,
        borderRadius: theme.borders.radius,
        fontWeight: 500,
        width: '100%',
        margin: '0 1rem',
        '&:hover': {
          color: theme.colors.secondary.color,
          background: theme.colors.secondary.contrastColor,
          border: `${theme.borders.size}px solid ${theme.colors.secondary.color}`,
        },
      })}
      href={src}
    >
      {label}
    </a>
  </div>
);
