import { pathOr } from 'ramda';

const server = process.env.REACT_APP_SERVER || 'https://api-server.leapti.de';

export const signUp = async ({
  username,
  password,
  passwordCopy,
  email,
}: {
  username: string;
  password: string;
  passwordCopy: string;
  email: string;
}) => {
  const res = await fetch(`${server}/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
      passwordCopy,
      email,
    }),
  });
  if (res.ok) {
    const json = await res.json();
    if (json.err) {
      return {
        err: true,
        message: `${json.message} ${JSON.stringify(
          pathOr(undefined, ['data', 'errors'], json),
        )}`,
      };
    }
    return json;
  }
  const json = await res.json();
  return json;
};

export const login = async (username: string, password: string) => {
  const res = await fetch(`${server}/login`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  });
  if (res.ok) {
    const json = await res.json();
    if (json.err) {
      return {
        err: true,
        message: `${json.message} graph error: ${JSON.stringify(
          pathOr(undefined, ['data', 'errors'], json),
        )}`,
      };
    }
    return json;
  }
  const json = await res.json();
  return json;
};

export const hasValidToken = async (
  username: string,
): Promise<{ tokenIsValid: false } | { tokenIsValid: true; id: string }> => {
  const r = await fetch(`${server}/verifyToken`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username }),
  });
  if (r.ok) {
    const json = await r.json();
    return { tokenIsValid: true, id: json.username };
  }
  const json = await r.json();
  return { tokenIsValid: true, id: json.data.username };
};
