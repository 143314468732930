export type GradientBackground = { start: string; end: string };

export interface Theme {
  borders: {
    size: number;
    radius: number;
  };
  colors: Colors;
}

export interface Colors {
  primary: { color: string; contrastColor: string };
  secondary: { color: string; contrastColor: string; opacity: number };
  background: GradientBackground;
}

export const defaultTheme: Theme = {
  borders: {
    size: 2,
    radius: 0,
  },
  colors: {
    primary: { color: '#ffffff', contrastColor: '#ffffff' },
    secondary: { color: '#2F3747', contrastColor: '#ffffff', opacity: 1 },
    background: { start: '#2F3747', end: '#2F3747' },
  },
};

export const sampleThemes: Theme[] = [
  defaultTheme,
  {
    borders: {
      size: 2,
      radius: 0,
    },
    colors: {
      primary: { color: '#71DCA1', contrastColor: '#71DCA1' },
      secondary: { color: '#71DCA1', contrastColor: '#ffffff', opacity: 1 },
      background: { start: '#FFFFFF', end: '#FFFFFF' },
    },
  },
  {
    colors: {
      primary: { color: '#3D3B3C', contrastColor: '#3D3B3C' },
      secondary: { color: '#3d3b3c', opacity: 1, contrastColor: '#ffffff' },
      background: { end: '#FFFFFF', start: '#FFFFFF' },
    },
    borders: { size: 2, radius: 0 },
  },
  {
    colors: {
      primary: { color: '#000000', contrastColor: '#3D3B3C' },
      secondary: { color: '#4f7cfe', opacity: 1, contrastColor: '#ffffff' },
      background: { end: '#FFFFFF', start: '#FFFFFF' },
    },
    borders: { size: 2, radius: 0 },
  },
  {
    colors: {
      primary: { color: '#cacaca', contrastColor: '#3D3B3C' },
      secondary: { color: '#fffcfc', opacity: 1, contrastColor: '#3d3c3c' },
      background: { end: '#3d3c3c', start: '#3d3c3c' },
    },
    borders: { size: 2, radius: 0 },
  },
  {
    colors: {
      primary: { color: '#fefdff', contrastColor: '#3D3B3C' },
      secondary: { color: '#7d7d7d', opacity: 0, contrastColor: '#fffcfc' },
      background: { end: '#9a9b9b', start: '#40414a' },
    },
    borders: { size: 2, radius: 0 },
  },
  {
    colors: {
      primary: { color: '#fefdff', contrastColor: '#3D3B3C' },
      secondary: { color: '#4be2a2', opacity: 0, contrastColor: '#fffcfc' },
      background: { end: '#aaf0cc', start: '#3ae09c' },
    },
    borders: { size: 2, radius: 0 },
  },
  {
    colors: {
      primary: { color: '#fefdff', contrastColor: '#3D3B3C' },
      secondary: { color: '#e77889', opacity: 0, contrastColor: '#fffcfc' },
      background: { end: '#fd8f68', start: '#d563a2' },
    },
    borders: { size: 2, radius: 0 },
  },
  {
    colors: {
      primary: { color: '#fefdff', contrastColor: '#3D3B3C' },
      secondary: { color: '#a078e7', opacity: 0, contrastColor: '#fffcfc' },
      background: { end: '#ffb9c7', start: '#6a50f6' },
    },
    borders: { size: 2, radius: 0 },
  },
  {
    colors: {
      primary: { color: '#fefdff', contrastColor: '#3D3B3C' },
      secondary: { color: '#5399fe', opacity: 0, contrastColor: '#fffcfc' },
      background: { end: '#76f3fa', start: '#3a50ff' },
    },
    borders: { size: 2, radius: 0 },
  },
];

export const loginFields = [
  { id: 'username', field: 'username', label: 'Username', type: 'username' },
  { id: 'password', field: 'password', label: 'Password', type: 'password' },
];

export const signUpFields = [
  {
    id: 'email',
    field: 'email',
    label: 'Email',
    type: 'email',
  },
  ...loginFields,
  {
    id: 'passwordCopy',
    field: 'passwordCopy',
    label: 'Re-enter Password',
    type: 'password',
  },
];
