import React, { ChangeEvent } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { css } from '@emotion/core';
import tw from 'tailwind.macro';
// @todo Storybook CSS Fix
/** @jsx jsx */
import { jsx } from '@emotion/core';

export const ErrorAwareInput: React.FC<{
  disabled?: boolean;
  error?: string;
  id: string;
  field: string;
  label: string;
  setFieldValue: (field: string, value: any) => void;
  type: string; // @todo make this a typed type not a free string
  value?: string;
}> = ({
  disabled = false,
  error,
  id,
  field,
  label,
  setFieldValue,
  type,
  value,
}) => (
  <div
    css={css`
      ${tw`flex flex-col mt-3`};
      width: 100%;
    `}
  >
    <TextField
      id={id}
      label={label}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        setFieldValue(field, event.target.value)
      }
      type={type}
      disabled={disabled}
      value={value}
      variant="outlined"
    />
    {error && (
      <Typography
        css={css`
          && {
            ${tw`mt-1`};
          }
        `}
        color="secondary"
      >
        {error}
      </Typography>
    )}
  </div>
);
