import { User, UsersSuccessPayload, Item } from '../types/Manifest';
import { pathOr } from 'ramda';
import { Theme } from '../structures';

const graphqlServerURL =
  process.env.REACT_APP_DB ||
  'https://simple-landing-page.herokuapp.com/v1/graphql';

const server = process.env.REACT_APP_SERVER || 'https://api-server.leapti.de';

const getUserFromGraphQl = (id: string) =>
  fetch(graphqlServerURL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `{ users( where: {id: {_eq: "${id}"}} ) { displayName, id, manifest, theme }}`,
    }),
  });

export const getUser = async (
  id?: string,
): Promise<undefined | null | User> => {
  if (id === undefined) {
    return null;
  }
  const graphQlQuery = await getUserFromGraphQl(id);
  if (graphQlQuery.ok) {
    const {
      data: { users },
    } = (await graphQlQuery.json()) as UsersSuccessPayload;
    return pathOr(null, [0], users);
  }
  return null;
};

export const updateUser = async (
  id: string,
  manifest: Item[],
  theme: Theme,
) => {
  const r = await fetch(`${server}/graph/updateUser`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: id,
      manifest,
      theme,
    }),
  });
  if (r.ok) {
    const json = await r.json();
    return json;
  }
  throw new Error('Couldnt Update User');
};
