import React from 'react';
import css from '@emotion/css';
import tw from 'tailwind.macro';
import { Theme } from '../structures';

export const Profile: React.FC<{
  username: string;
  src: string;
  theme: Theme;
}> = ({ username, src, theme }) => (
  <div
    css={css`
      ${tw`mt-4 flex flex-col justify-center items-center`};
    `}
  >
    <div>
      <img
        alt="profile"
        css={css`
          height: 100px;
          width: 100px;
          border-radius: 50%;
        `}
        src={src}
      ></img>
    </div>
    <div
      css={css`
        ${tw`font-semibold leading-tight`};
        color: ${theme.colors.primary.color};
      `}
    >
      @{username}
    </div>
  </div>
);
