import React from 'react';
import YouTube, { Options } from 'react-youtube';
import css from '@emotion/css';
import tw from 'tailwind.macro';

const targetRefDeps = (
  targetRef: React.MutableRefObject<null | HTMLDivElement>,
): [React.MutableRefObject<null | HTMLDivElement>, 0 | number] => {
  if (targetRef !== null && targetRef.current !== null) {
    return [targetRef, targetRef.current.clientHeight];
  }
  return [targetRef, 0];
};

export const YoutubeVideo: React.FC<{ videoId: string }> = ({ videoId }) => {
  const targetRef = React.useRef<null | HTMLDivElement>(null);
  const [contentWidth, setContentWidth] = React.useState(640);

  React.useLayoutEffect(() => {
    try {
      if (targetRef === null || targetRef.current === null) {
        return;
      }
      setContentWidth(Math.min(1000, targetRef.current.clientWidth));
    } catch (e) {}
    // eslint-disable-next-line
  }, targetRefDeps(targetRef)); // you should pass an array literal so that tslint can tell you if your deps are wrong, instead I need to optionally return a clientHeight or 0 when its not available

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      try {
        if (targetRef === null || targetRef.current === null) {
          return;
        }
        setContentWidth(Math.min(1000, targetRef.current.clientWidth));
      } catch (e) {}
    });
  });

  const opts: Options = {
    width: `${contentWidth}`,
    height: `${(contentWidth / 16) * 9}`,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <div
      ref={targetRef}
      css={css`
        ${tw`flex justify-center`}
      `}
    >
      <YouTube
        videoId={videoId}
        opts={opts}
        onReady={(event: any) => {
          event.target.mute();
          event.target.playVideo();
        }}
      ></YouTube>
    </div>
  );
};
