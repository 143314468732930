import * as React from 'react';
import { Field } from 'formik';
import { ColumnLabel } from './ColumnLabel';
import css from '@emotion/css';
import { sampleThemes, Theme } from '../structures';
import { ThemeButton } from './ThemeButton';

export const ThemeEditor: React.FC<{
  setFieldValue: (field: string, val: any) => void;
}> = ({ setFieldValue }) => {
  return (
    <>
      <div>
        <div>
          <div
            css={css`
              font-weight: 700;
              margin-top: 0.5rem;
            `}
          >
            Themes
          </div>
          <div>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                flex-flow: wrap;
              `}
            >
              {sampleThemes.map(theme => (
                <ThemeButton
                  theme={theme}
                  setTheme={(theme: Theme) => setFieldValue('theme', theme)}
                ></ThemeButton>
              ))}
            </div>
          </div>
        </div>
        <div>
          <div
            css={css`
              font-weight: 700;
              margin-top: 1rem;
            `}
          >
            Customize
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              flex-flow: wrap;
=            `}
          >
            <ColumnLabel label="primary">
              <Field
                css={css`
                  width: 100%;
                `}
                type="color"
                name="theme.colors.primary.color"
                id="primary-color"
              />
            </ColumnLabel>
            <ColumnLabel label="secondary">
              <Field
                css={css`
                  width: 100%;
                `}
                type="color"
                name="theme.colors.secondary.color"
                id="secondary-color"
              />
            </ColumnLabel>
            <ColumnLabel label="secondary contrast">
              <Field
                css={css`
                  width: 100%;
                `}
                type="color"
                name="theme.colors.secondary.contrastColor"
                id="secondary-contrast-color"
              />
            </ColumnLabel>
            <ColumnLabel label="background start">
              <Field
                css={css`
                  width: 100%;
                `}
                type="color"
                name="theme.colors.background.start"
                id="primary-color"
              />
            </ColumnLabel>
            <ColumnLabel label="background end">
              <Field
                css={css`
                  width: 100%;
                `}
                type="color"
                name="theme.colors.background.end"
                id="primary-color"
              />
            </ColumnLabel>
          </div>
          <div
            css={css`
              display: flex;
              margin-top: 1rem;
            `}
          >
            <ColumnLabel label="border size">
              <Field
                css={css`
                  width: 100%;
                `}
                type="number"
                name="theme.borders.size"
                id="border-size"
              />
            </ColumnLabel>
            <ColumnLabel label="border radius">
              <Field
                css={css`
                  width: 100%;
                `}
                type="number"
                name="theme.borders.radius"
                id="border-radius"
              />
            </ColumnLabel>
          </div>
        </div>
      </div>
    </>
  );
};
