import React from 'react';
import { Item, ItemType } from '../types/Manifest';
import { Title } from './Title';
import { YoutubeVideo } from './Video';
import { Link } from './Link';
import { Profile } from './Profile';
import { Theme } from '../structures';

export const LandingPageItem: React.FC<{ item: Item; theme: Theme }> = ({
  item,
  theme,
}) => {
  switch (item.type) {
    case ItemType.Title:
      return <Title>{item.value}</Title>;
    case ItemType.YoutubeVideo:
      return <YoutubeVideo videoId={item.value}></YoutubeVideo>;
    case ItemType.Link:
      return <Link {...item.value}></Link>;
    case ItemType.Instagram:
      return <Profile theme={theme} {...item.value}></Profile>;
  }
};
