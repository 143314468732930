import React from 'react';
import { Theme } from '../structures';
import css from '@emotion/css';
import { generateBackground } from '../pages';
import { Button } from '@material-ui/core';
// @todo Storybook CSS Fix
/** @jsx jsx */
import { jsx } from '@emotion/core';
import tw from 'tailwind.macro';

export const ThemeButton: React.FC<{
  theme: Theme;
  setTheme: (theme: Theme) => void;
}> = ({ theme, setTheme }) => {
  const backgroundGradient = generateBackground(theme.colors.background);
  return (
    <Button onClick={() => setTheme(theme)}>
      <span
        css={css`
          display: flex;
          width: 100px;
          height: 100px;
          justify-content: center;
          background: ${backgroundGradient};
          ${tw`items-center`};
        `}
      >
        <span
          css={css`
            width: 50px;
            height: 50px;
            border-radius: ${theme.borders.radius};
            border: ${theme.borders.size}px solid
              ${theme.colors.secondary.contrastColor};
            background: ${theme.colors.secondary.opacity === 0
              ? 'transparent'
              : theme.colors.secondary.color};
          `}
        ></span>
      </span>
    </Button>
  );
};
