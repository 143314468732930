import React, { ChangeEvent } from 'react';
import { Item, ItemType } from '../types/Manifest';
import { Card, Button, TextField } from '@material-ui/core';
import css from '@emotion/css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { insert } from 'ramda';
import uuidv1 from 'uuid/v1';
import tw from 'tailwind.macro';
import styled from '@emotion/styled';

const addItem = (
  type: ItemType,
  items: Item[],
  setFieldValue: (field: string, value: any) => void,
) => {
  const item = newItem(type);
  setFieldValue('manifest', [...items, item]);
};

const NewItemButton = styled(Button)`
  && {
    color: white;
    border-color: white;
    margin: 0 0.5rem;
  }
`;

const newItem = (type: ItemType): Item => {
  switch (type) {
    case ItemType.Title:
      return {
        type,
        id: uuidv1(),
        value: '',
      };
    case ItemType.YoutubeVideo:
      return {
        type,
        id: uuidv1(),
        value: '',
      };
    case ItemType.Link:
      return {
        type,
        id: uuidv1(),
        value: {
          label: '',
          src: '',
        },
      };
    case ItemType.Instagram:
      return {
        type,
        id: uuidv1(),
        value: {
          src: '',
          username: '',
        },
      };
  }
};

const renderItem = (
  item: Item,
  index: number,
  handleChange: (e: ChangeEvent<any>) => void,
) => {
  switch (item.type) {
    case ItemType.Title:
      return (
        <TextField
          css={css`
            width: 100%;
          `}
          onChange={handleChange}
          id={`content-item-${item.id}`}
          name={`manifest.${index}.value`}
          placeholder="Title"
          value={item.value}
        ></TextField>
      );
    case ItemType.YoutubeVideo:
      return (
        <TextField
          css={css`
            width: 100%;
          `}
          onChange={handleChange}
          id={`content-item-${item.id}`}
          name={`manifest.${index}.value`}
          placeholder="Video Id"
          value={item.value}
        ></TextField>
      );
    case ItemType.Link:
      return (
        <div
          css={css`
            flex-grow: 1;
            display: flex;
            flex-direction: column;
          `}
        >
          <TextField
            css={css`
              flex-grow: 1;
            `}
            onChange={handleChange}
            id={`content-item-${item.id}`}
            name={`manifest.${index}.value.label`}
            placeholder="Link Label"
            value={item.value.label}
          ></TextField>
          <TextField
            css={css`
              flex-grow: 1;
            `}
            onChange={handleChange}
            id={`content-item-${item.id}`}
            name={`manifest.${index}.value.src`}
            placeholder="Link Src"
            value={item.value.src}
          ></TextField>
        </div>
      );
    case ItemType.Instagram:
      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          `}
        >
          <TextField
            css={css`
              flex-grow: 1;
            `}
            onChange={handleChange}
            id={`content-item-${item.id}`}
            name={`manifest.${index}.value.username`}
            placeholder="Username"
            value={item.value.username}
          ></TextField>
          <TextField
            css={css`
              flex-grow: 1;
            `}
            onChange={handleChange}
            id={`content-item-${item.id}`}
            name={`manifest.${index}.value.src`}
            placeholder="Image URL"
            value={item.value.src}
          ></TextField>
        </div>
      );
  }
};

export const ContentEditor: React.FC<{
  items: Item[];
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
}> = ({ items, setFieldValue, handleChange }) => {
  const onDragEnd = (ev: any) => {
    const {
      draggableId,
      destination: { index },
    }: { draggableId: string; destination: { index: number } } = ev;
    const item = items.find(i => i.id === draggableId);
    const restItems = items.filter(i => i.id !== draggableId);
    const newItems = insert(index, item, restItems);
    setFieldValue('manifest', newItems);
  };

  return (
    <div>
      <div
        css={css`
          ${tw`flex flex-col items-center`};
        `}
      >
        <div
          css={css`
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 23px;
          `}
        >
          ADD A NEW ITEM
        </div>
        <div
          css={css`
            ${tw`flex px-8 py-2 rounded`};
            background: #71c2dc;
          `}
        >
          <NewItemButton
            onClick={() => {
              addItem(ItemType.Link, items, setFieldValue);
            }}
            variant="outlined"
          >
            Link
          </NewItemButton>
          <NewItemButton
            onClick={() => {
              addItem(ItemType.Instagram, items, setFieldValue);
            }}
            variant="outlined"
          >
            Profile
          </NewItemButton>
          <NewItemButton
            onClick={() => {
              addItem(ItemType.Title, items, setFieldValue);
            }}
            variant="outlined"
          >
            Headline
          </NewItemButton>
          <NewItemButton
            onClick={() => {
              addItem(ItemType.YoutubeVideo, items, setFieldValue);
            }}
            variant="outlined"
          >
            Youtube
          </NewItemButton>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div
              css={css`
                ${tw`mt-4`};
              `}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        css={css`
                          ${snapshot.isDropAnimating ? 'margin-top: -11px' : ''}
                        `}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Card
                          variant="outlined"
                          css={css`
                            margin: 0.5rem 0;
                          `}
                        >
                          <div
                            css={css`
                              ${tw`flex justify-between`};
                            `}
                          >
                            <div
                              css={css`
                                display: flex;
                                align-items: center;
                                background: #dcdee0;
                              `}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path fill="none" d="M0 0h24v24H0V0z" />
                                <path
                                  fill="#9298A0"
                                  d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                                />
                              </svg>
                            </div>
                            <div
                              css={css`
                                padding: 0.5rem;
                                flex-grow: 1;
                              `}
                            >
                              {renderItem(item, index, handleChange)}
                            </div>
                            <Button
                              color="secondary"
                              onClick={() =>
                                setFieldValue(
                                  'manifest',
                                  items.filter((item, i) => i !== index),
                                )
                              }
                            >
                              Remove
                            </Button>
                          </div>
                        </Card>
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
