import React from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { User } from '../types/Manifest';
import { getUser, updateUser } from '../services/graphql-service';
import { NotFoundPage } from './NotFoundPage';
import { ThemeEditor } from '../components/ThemeEditor';
import { ContentEditor } from '../components/ContentEditor';
import { mergeDeepRight } from 'ramda';
import { defaultTheme } from '../structures';
import { LoadingPage } from './LoadingPage';
import { Formik } from 'formik';
import css from '@emotion/css';
import tw from 'tailwind.macro';
import { UserPage } from './UserPage';
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core';

function copyStringToClipboard(str: string) {
  // Create new element
  const el = document.createElement('textarea');
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '');
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand('copy');
  // Remove temporary element
  document.body.removeChild(el);
}

export const ConfigurePage: React.FC<{ hostname: string }> = ({ hostname }) => {
  const { id } = useParams();
  const [value, setValue] = React.useState('one');
  const [user, setUser] = React.useState<undefined | null | User>(undefined);
  React.useEffect(() => {
    (async () => {
      const remoteUser = await getUser(id);
      setUser(remoteUser);
    })();
  }, [id]);

  if (user === undefined) {
    return <LoadingPage />;
  }

  if (user === null) {
    return <NotFoundPage />;
  }

  const { theme = {}, manifest } = user;
  const mergedTheme = mergeDeepRight(defaultTheme, theme);

  function a11yProps(index: any) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        css={css`
          & > div {
            padding: 0;
            padding-bottom: 1rem;
          }
        `}
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Formik
      onSubmit={({ theme, manifest }) => {
        updateUser(user.id, manifest, theme);
      }}
      initialValues={{ manifest, theme: mergedTheme }}
    >
      {({ values, handleSubmit, setFieldValue, handleChange }) => (
        <div
          css={css`
            ${tw`flex flex-top`};
            background-color: #f5f5f5;
          `}
        >
          <div
            css={css`
              border-right: 2px solid #c4c4c4;
              min-width: 60%;
            `}
          >
            <div
              css={css`
                flex-grow: 1;
              `}
            >
              <ThemeProvider
                theme={createMuiTheme({
                  palette: {
                    primary: { main: '#ffffff', contrastText: '#303746' },
                    secondary: { main: '#71C2DC' },
                  },
                })}
              >
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleTabChange}
                    aria-label="wrapped label tabs example"
                  >
                    <Tab
                      value="one"
                      label="Content"
                      wrapped
                      {...a11yProps('one')}
                    />
                    <Tab value="two" label="Theme" {...a11yProps('two')} />
                  </Tabs>
                </AppBar>
              </ThemeProvider>

              <div
                css={css`
                  ${tw`p-8 pt-2`}
                `}
              >
                <TabPanel value={value} index="one">
                  <ContentEditor
                    items={values.manifest}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                  />
                </TabPanel>
                <TabPanel value={value} index="two">
                  <ThemeEditor setFieldValue={setFieldValue} />
                </TabPanel>
                <div
                  css={css`
                    margin-top: 1rem;
                  `}
                >
                  <Button
                    onClick={(event: any) => {
                      handleSubmit(event);
                    }}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div
            css={css`
              width: 100%;
            `}
          >
            <ThemeProvider
              theme={createMuiTheme({
                palette: {
                  primary: { main: '#ffffff', contrastText: '#303746' },
                  secondary: { main: '#71C2DC' },
                },
              })}
            >
              <AppBar
                css={css`
                  && {
                    height: 48px;
                    display: flex;
                    justify-content: center;
                  }
                `}
                position="static"
              >
                <div
                  css={css`
                    && {
                      padding: 0 1rem;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    }
                  `}
                >
                  <div
                    css={css`
                      && {
                        display: flex;
                        align-items: center;
                      }
                    `}
                  >
                    <Typography>Preview Link:</Typography>
                    <RouterLink
                      rel="noopener noreferrer"
                      target="_blank"
                      css={css`
                        color: #303746;
                        margin-left: 1rem;
                        margin-top: -2px;
                        font-weight: 300;
                        &:visited {
                          color: #303746;
                        }
                        &:hover {
                          text-decoration: none;
                        }
                      `}
                      to={`/${id}`}
                    >
                      {hostname}/{id}
                    </RouterLink>
                  </div>
                  <Button
                    onClick={() => copyStringToClipboard(`${hostname}/${id}`)}
                    variant="outlined"
                  >
                    Copy Link
                  </Button>
                </div>
              </AppBar>
            </ThemeProvider>
            <div
              css={css`
                ${tw`flex flex-col`};
                min-height: calc(100vh - 148px);
                margin: 0 auto;
                margin-top: 50px;
              `}
            >
              <div
                css={css`
                  max-width: 320px;
                  min-height: 500px;
                  border: 10px solid black;
                  border-radius: 25px;
                  background: black;
                  margin: 0 auto;
                `}
              >
                <UserPage
                  borderRadius={20}
                  user={{
                    ...user,
                    manifest: values.manifest,
                    theme: values.theme,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
