import React from 'react';
import css from '@emotion/css';

export const ColumnLabel: React.FC<{ label: string }> = ({
  label,
  children,
}) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      width: 110px;
      margin: 0 0.5rem;
    `}
  >
    <div
      css={css`
        font-size: 9px;
        font-weight: 300;
        text-transform: uppercase;
        text-align: center;
      `}
    >
      {label}
    </div>
    {children}
  </div>
);
