import { Theme } from '../structures';

export type Manifest = Item[];

type ProtoItem<T, V> = {
  id: string;
  type: T;
  value: V;
};

export enum ItemType {
  Title = 'title',
  YoutubeVideo = 'video',
  Link = 'link',
  Instagram = 'instagram',
}

export type Title = ProtoItem<ItemType.Title, string>;
export type Video = ProtoItem<ItemType.YoutubeVideo, string>;
export type Link = ProtoItem<ItemType.Link, { label: string; src: string }>;
export type InstagramProfile = ProtoItem<
  ItemType.Instagram,
  { src: string; username: string }
>;

export type Item = Title | Video | Link | InstagramProfile;

export type User = {
  displayName: string;
  id: string;
  manifest: Manifest;
  theme: Partial<Theme>;
};

export type UsersSuccessPayload = {
  data: {
    users: User[];
  };
};
