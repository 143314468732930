import React from 'react';
import { defaultTheme, Theme, GradientBackground } from '../structures';
import { User } from '../types/Manifest';
import { LandingPageItem } from '../components/LandingPageItem';
import { ThemeProvider } from 'emotion-theming';
import { mergeDeepRight } from 'ramda';

export const generateBackground = (background: GradientBackground) => {
  return `linear-gradient(180deg, ${background.start} 30%, ${background.end} 100%);`;
};

export const UserPage: React.FC<{ user: User; borderRadius?: number }> = ({
  user,
  borderRadius,
}) => {
  document.title = user.displayName;
  const theme = mergeDeepRight(defaultTheme, user.theme);
  return (
    <ThemeProvider theme={theme}>
      <div
        css={(theme: Theme) => ({
          height: 'inherit',
          minHeight: 500,
          width: '100%',
          background: generateBackground(theme.colors.background),
          paddingBottom: '2rem',
          borderRadius: borderRadius,
          overflow: 'hidden',
        })}
      >
        {user.manifest.map(item => (
          <LandingPageItem key={item.id} theme={theme} item={item} />
        ))}
      </div>
    </ThemeProvider>
  );
};
