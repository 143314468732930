import React from 'react';
import { Theme } from '../structures';
import styled from '@emotion/styled';
import tw from 'tailwind.macro';

const TailwindContainer = styled.div`
  ${tw`flex justify-center mt-2 text-4xl leading-tight xl:text-5xl font-semibold font-display text-white mb-8 m-4`}
  text-align: center;
`;

export const Title: React.FC = ({ children }) => (
  <TailwindContainer
    css={(theme: Theme) => ({
      color: theme.colors.primary.color,
    })}
  >
    {children}
  </TailwindContainer>
);
