import React from 'react';
import { css } from '@emotion/core';

export const LoadingPage = () => (
  <div
    css={css`
      height: calc(100vh - 48px);
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  >
    <img
      css={css`
        width: 80px;
        height: 80px;
      `}
      alt="loading-spinner"
      src={`${process.env.PUBLIC_URL}/assets/loading-spinner.gif`}
    />
  </div>
);
