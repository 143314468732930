import React from 'react';
import { getUser } from '../services/graphql-service';
import { User } from '../types/Manifest';
import { useParams } from 'react-router';
import { NotFoundPage, LoadingPage, UserPage } from '../pages';
import css from '@emotion/css';

export const LandingPage: React.FC = () => {
  const { id } = useParams();
  const [user, setUser] = React.useState<undefined | null | User>(undefined);
  React.useEffect(() => {
    (async () => {
      const remoteUser = await getUser(id);
      setUser(remoteUser);
    })();
  }, [id]);

  if (user === null) {
    return <NotFoundPage />;
  }

  if (user === undefined) {
    return <LoadingPage />;
  }

  return (
    <div
      css={css`
        display: flex;
        min-height: 100vh;
      `}
    >
      <UserPage user={user} />
    </div>
  );
};
