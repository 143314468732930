import React from 'react';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { object, string } from 'yup';
import { Button } from '@material-ui/core';
import { signUp, login } from '../services/auth-service';
import { Redirect } from 'react-router';
import css from '@emotion/css';
import tw from 'tailwind.macro';
import { signUpFields, loginFields } from '../structures';
import { ErrorAwareInput } from '../components/ErrorAwareInput/ErrorAwareInput';

interface FormFields {
  username: string;
  password: string;
  passwordCopy: string;
  email: string;
}

export const LoginPage: React.FC<{ newUser?: boolean }> = ({
  newUser = false,
}) => {
  const validationSchema = object({
    username: string().required('Username is a required field.'),
    password: string().required('Password is a required field.'),
    passwordCopy: newUser
      ? string().required('Password Copy is a required field.')
      : string(),
    email: newUser ? string().required('Email is a required field.') : string(),
  });

  const [loginState, setLoginState] = React.useState<
    { success: true; id: string } | { success: false; error?: string }
  >({ success: false });
  if (loginState.success) {
    return <Redirect to={`/configure/${loginState.id}`} />;
  }
  const tryAuth = async ({
    username,
    password,
    passwordCopy,
    email,
  }: FormFields) => {
    try {
      if (newUser) {
        const r = await signUp({ username, password, passwordCopy, email });
        if (r.err) {
          alert(`Error ${r.status} - ${r.message}`);
          return setLoginState({ success: false, error: r.message });
        }
        return setLoginState({ success: true, id: username });
      }
      const r = await login(username, password);
      if (r.err) {
        alert(r.message);
        return setLoginState({ success: false, error: r.message });
      }
      console.log('success', username);
      return setLoginState({ success: true, id: username });
    } catch (e) {
      console.log(e);
    }
  };

  const fields = newUser ? signUpFields : loginFields;

  const getValue = (
    values: FormFields,
    key: 'username' | 'password' | 'passwordCopy' | 'email',
  ) => values[key];

  const getError = (
    errors: FormikErrors<FormFields>,
    key: 'username' | 'password' | 'passwordCopy' | 'email',
  ) => errors[key];

  const wasTouched = (
    touched: FormikTouched<FormFields>,
    key: 'username' | 'password' | 'passwordCopy' | 'email',
  ) => touched[key];

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        onSubmit={({ username, password, passwordCopy, email }) =>
          tryAuth({ username, password, passwordCopy, email })
        }
        initialValues={{
          username: '',
          password: '',
          passwordCopy: '',
          email: '',
        }}
      >
        {({
          errors,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <div
              css={css`
                ${tw`flex flex-col items-center m-auto mt-8`};
                width: 400px;
              `}
            >
              {fields.map(field => (
                <ErrorAwareInput
                  key={field.id}
                  {...field}
                  setFieldValue={setFieldValue}
                  disabled={isSubmitting}
                  value={getValue(
                    values,
                    field.id as
                      | 'username'
                      | 'password'
                      | 'passwordCopy'
                      | 'email',
                  )}
                  error={
                    wasTouched(
                      touched,
                      field.id as
                        | 'username'
                        | 'password'
                        | 'passwordCopy'
                        | 'email',
                    )
                      ? getError(
                          errors,
                          field.id as
                            | 'username'
                            | 'password'
                            | 'passwordCopy'
                            | 'email',
                        )
                      : undefined
                  }
                />
              ))}
              <Button
                css={css`
                  && {
                    margin-top: 0.5rem;
                  }
                `}
                type="submit"
                disabled={isSubmitting}
              >
                <>
                  {isSubmitting && (
                    <img
                      css={css`
                        width: 40px;
                        height: 40px;
                      `}
                      alt="loading-spinner"
                      src={`${process.env.PUBLIC_URL}/assets/loading-spinner.gif`}
                    />
                  )}
                  <span>{newUser ? 'Sign Up' : 'Login'}</span>
                </>
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
